import React, { createRef, useEffect } from "react"
import { graphql } from "gatsby"
import MarkDown from "react-markdown"

//components
import Layout from "../components/layout";
import Seo from "../components/seo";
import LiveChat from "../components/Home/livechat";

//animation components
import ProcessFlow from "../components/Animation/process-flow";

//lottie animation
import lottie from 'lottie-web';
import animationData from '../../asset/animationHome.json';

const OurProcess = ({ data, location }) => {

  let animationContainer = createRef();
  let anim = null;

  useEffect(() => {
    anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData
    });
    return () => anim.destroy();
  }, []);

  const info = data.ourProcessData
  const SEOdata = data.awardpageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = data.awardpageData.edges[0].node.frontmatter?.Schema
  const Hreflang = data.awardpageData.edges[0].node.frontmatter?.Hreflang

  return (
    <>
      <Layout>
      <Seo
        title={SEOdata ? SEOdata.seo_title : "our-process"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
      />

        <section className="our-process-main h-full lg:h-screen lg:min-h-768 relative ">
          <div className="cstm-process-lottie-animation absolute">
            <div className="h-full" ref={animationContainer}></div>
          </div>

          {/* banner content */}
          <div className="pt-1-25 lg:pb-54 ">
            <div className="container mx-auto">
              <div className="pb-35 lg:pb-47 md:w-4/5 lg:w-3/4 xl:w-3/5 mx-auto text-center relative">
                <h2 className="pb-3-1 lg:pb-3-2 text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-cyan text-center">
                  {info.frontmatter.title}
                  <br></br>
                  <span className="text-term-primary">
                    {info.frontmatter.sub_title}
                  </span>
                </h2>
                <MarkDown
                  className="px-4 lg:px-0 xl:px-10 text-p4 lg:text-p2 font-worksans-normal text-term-primary text-center about-text"
                  children={info.frontmatter.introduction}
                  allowDangerousHtml
                />
              </div>
            </div>
          </div>
        </section>

        {/* discovery process section */}

        <section className="3md:mt-49">
          <div className="container mx-auto">
            <div className="w-auto ms:w-4-00 mx-auto">
              <h2 className="pb-1-1 text-rh2 lg:text-h2 text-term-primary font-poppings-bold text-center">
                {" "}
                Discovery{" "}
              </h2>
              <MarkDown
                className="pb-6-2 text-p4 lg:text-p2 font-worksans-normal text-term-primary text-center"
                children={info.frontmatter.discovery.body1}
                allowDangerousHtml
              />
            </div>

            {/* process flow section */}

            <div className="our-process-svg-animation">
              <ProcessFlow />
            </div>

            <div className="mb-50 lg:-mt-0 xl:-mt-0 xl:mb-1-08 md:w-6-30 lg:w-7-70 mx-auto">
              <MarkDown
                className="pb-0 text-p4 lg:text-p2 text-term-primary font-worksans-normal text-center"
                children={info.frontmatter.discovery.body2}
                allowDangerousHtml
              />
            </div>
          </div>
        </section>

        {/* schedule a free cunsultation chat section */}
        <LiveChat />
      </Layout>
    </>
  )
}

export default OurProcess;

export const query = graphql`
query {
  ourProcessData: markdownRemark(fileAbsolutePath: {regex: "/our-process.md/"}) {
    frontmatter {
      title
      sub_title
      introduction
      alt
      discovery {
        body1
        body2
      }
    }
  }
  awardpageData: allMarkdownRemark(
    filter: { fileAbsolutePath: {regex: "/our-process.md/"} }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }
}
`;